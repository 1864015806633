.contact-info {
    padding: rem(50);

    span {
        font-weight: 600;
        font-size: rem(18);
        color: #000;
    }

    a {
        text-decoration: none;
        color: #000;
    }
}


footer {
    background: #f5f5f5;
    padding: rem(50);
    font-family: 'Raleway', sans-serif !important;

    span {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        color: #000;
        transition-duration: 0.3s;

        &:hover {
            color: #777777;
            transition-duration: 0.3s;
        }
    }
}