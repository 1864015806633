@import "./../function";

$image1: "./../../assets/contact/contact-top-bg.jpg";
$image2: "./../../assets/contact/contact-bg.jpg";

.home__slide {
    background: url('./../../assets/home-slide/bg.jpg') no-repeat;
    height: rem(620);
    font-family: 'Raleway', sans-serif !important;
    background-size: cover;
    background-position: center;
    margin-top: -120px;

    @include media-breakpoint-down(lg) {
        background-size: cover;
        min-height: rem(250);
        max-height: rem(400);
        margin-top: -120px;
    }

    &__inner {
        background: transparent;
        height: rem(450);
        width: rem(730);

        @include media-breakpoint-down(lg) {
            width: 100%;
            margin-left: rem(0);
            background: transparent;
            height: 100%;
            padding-top: 30px;
        }

        &--text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-top: 220px;

            @include media-breakpoint-down(lg) {
                padding-top: 150px;
            }

            span {
                font-weight: 600;
                font-size: rem(36);
                line-height: 1.4;
                color: #fff;
                margin-bottom: rem(20);

                @include media-breakpoint-down(lg) {
                    font-size: rem(18);
                    color: #fff;
                }
            }

            p {
                font-weight: 400;
                font-size: rem(18);
                line-height: 1.6;
                color: #fff;

                @include media-breakpoint-down(lg) {
                    font-size: rem(16);
                    color: #fff;
                }
            }
        }

        &--line {
            background: #fff;
            height: rem(4);
            width: 20%;
            margin-top: rem(-10);

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}

.home__about {
    padding: rem(100) 0;
    font-family: 'Raleway', sans-serif !important;

    @include media-breakpoint-down(lg) {
        padding: rem(40) 0;
    }

    &--text {
        span {
            font-weight: 600;
            font-size: rem(36);
            line-height: 1.6;
            color: #000;
        }

        p {
            font-weight: 500;
            font-size: rem(18);
            line-height: 1.6;
            color: #000;
            margin-top: rem(20);
            text-align: justify;
            padding-right: rem(20);
        }
    }

    img {
        border-bottom-left-radius: rem(80);
        max-height: 380px;
        object-fit: cover;
    }

    &--line {
        background: #000;
        height: rem(4);
        width: 50%;
        margin-top: rem(40);

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.contact__top {

    background: url($image1) no-repeat;
    width: 100%;
    min-height: rem(250);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down(lg) {
        background-attachment: scroll;
    }
}

.contact__box {
    background: #f5f5f5 url($image2) no-repeat;
    background-position: 60%;
    background-size: cover;
    width: 100%;
    display: flex;
    padding: rem(80);
    margin-bottom: rem(100);

    @include media-breakpoint-down(lg) {
        padding: rem(40) 0;
        background: #f5f5f5;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--text {
            span {
                font-weight: 600;
                font-size: rem(36);
                line-height: 1.4;
                color: #000;
            }

            p {
                font-weight: 400;
                font-size: rem(18);
                line-height: 1.4;
                color: #000;
                margin-top: rem(25);
                margin-bottom: rem(30);
            }
        }

        &--form {

            input,
            textarea {
                background: #fff;
                color: #000;
                padding: rem(18);
                border: none;
                font-weight: 600;
                border-radius: 0;

                &::placeholder {
                    color: #000;
                    font-weight: 600;
                }
            }

            .btn {
                width: 100%;
                border-radius: 0;
                border: rem(2) solid #d4d4d4;
                color: #000;
                font-weight: 600;
                background: transparent;
                padding: rem(18);

                &:hover {
                    background: #777;
                    color: #fff;
                    border: rem(2) solid #777;
                }
            }

        }

        &--contact-form {

            input,
            textarea {
                background: #fff;
                color: #000;
                padding: rem(18);
                border: rem(2) solid #d4d4d4;
                font-weight: 600;
                border-radius: 0;

                &::placeholder {
                    color: #000;
                    font-weight: 600;
                }
            }

            .btn {
                width: 100%;
                border-radius: 0;
                border: rem(2) solid #d4d4d4;
                color: #000;
                font-weight: 600;
                background: transparent;
                padding: rem(18);

                &:hover {
                    background: #777;
                    color: #fff;
                    border: rem(2) solid #d4d4d4;
                }
            }

        }
    }
}

.home__offer {
    padding-top: rem(50);
    padding-bottom: rem(50);
    background: #f5f5f5;

    .home__offer--heading {
        margin-bottom: rem(40);

        span {
            font-size: rem(36);
            font-weight: 600;
        }
    }

    .home__offer_inner {
        margin-bottom: rem(60);

        .home__offer_inner--text {
            position: absolute;
            margin-top: rem(327);
            background: #658053;
            padding: rem(10) rem(30);
            min-width: rem(200);
            text-align: center;
            border-top-right-radius: rem(40);

            span {
                font-size: rem(22);
                font-weight: 600;
                text-transform: uppercase;
                color: #fff;
            }
        }

        .home__offer_inner--img {
            img {
                min-height: rem(380);
                max-height: rem(380);
                object-fit: cover;
                width: 100%;
            }

        }

    }

    .home__offer_text {
        &--left {
            padding-top: 60px;

            @include media-breakpoint-down(lg) {
                padding-top: 0;
            }
            span {
                font-size: rem(32);
                font-weight: 600;
            }

            p {
                font-size: rem(18);
                font-weight: 500;
                margin-top: rem(20);
                text-align: justify;
                padding-right: rem(20);
            }
        }
    }
}

.btl-radius {
    border-top-left-radius: rem(40);
}

.bbr-radius {
    border-bottom-right-radius: rem(40);
}

.form-check-input[type="checkbox"] {
    padding: 2px;
}

small {
    font-size: 14px !important;
    line-height: 1.4;
    text-align: justify;
}