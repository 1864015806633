@import "./function";
@import '../App.scss';

.header-sticky {
    min-height: rem(60) !important;
}

.header {
    min-height: rem(110);
    font-family: 'Raleway', sans-serif !important;
    background: rgba(0, 0, 0, 0.3);
    //z-index: 9999;

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }

    @include media-breakpoint-down(lg) {
        background: rgba(0, 0, 0, 1);
        border: none;
        min-height: auto;
    }

    .container {
        .nav-item {
            padding-right: rem(20);
            margin-top: 1px;

            .nav-link {
                font-size: rem(18);
                font-weight: 600;
                color: #fff;
                border-bottom: 1px solid transparent;

                &:hover {
                    color: #fff;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                }
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        min-height: auto;
    }
}

.header_black {
    min-height: rem(110);
    font-family: 'Raleway', sans-serif !important;
    border-bottom: 2px solid;
    // z-index: 9999;

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }

    .container {
        .nav-item {
            padding-right: rem(20);

            .nav-link {
                font-size: rem(18);
                font-weight: 600;
                color: #000;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        min-height: auto;
    }
}